/* globals heap */
import React, { useEffect, useState, useContext, useRef } from 'react'
import { useFirebaseService, getProvider, createCredentials } from 'theme/services/firebase-service'
import ApiGateway from 'theme/api/api-gateway'
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithCustomToken,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateProfile,
  sendEmailVerification,
  getAdditionalUserInfo,
  reauthenticateWithCredential,
  updatePassword,
  getIdTokenResult,
  updateEmail,
} from 'firebase/auth'
import { getDatabase, ref, onValue, get, off, set, update, DataSnapshot } from 'firebase/database'
import { splitDisplayName } from 'theme/utils/auth-helpers'
import { AuthUser, SaveFunction, AuthSubscription } from 'theme/services/auth/auth-service.interfaces'

export interface IAuthProvider {
  user: any
  updateUser: any
  authenticated: any
  loadingAuthState: any
  isVerified: any
  logout: any
  register: any
  resetTestUserSessions: any
  setTestUserSessionLimit: any
  loginWithProvider: (providerName: string) => Promise<void>
  loginWithToken: (token: string) => Promise<void>
  loginWithEmail: any
  forgotPassword: any
  openLoginModal: (callback?: (...params: any) => void) => void
  setRegistering: any
  handleModalClose: any
  loginModal: any
  reauthenticate: any
  updatePassword: any
  addActiveSubscription: any
  removeActiveSubscriptions: any
  getOrders: any
}

export const AuthContext = React.createContext<IAuthProvider | null>(null)

export const AuthProvider = ({ children, disabled }) => {
  const firebaseApp = useFirebaseService()
  const [user, setUser] = useState<AuthUser | null>(null)
  const [loadingAuthState, setLoadingAuthState] = useState(true)
  const [loginModal, setLoginModal] = useState({
    isVisible: false,
    registering: false,
    closeCallback: () => undefined,
  })
  const activeSubscriptions = useRef<AuthSubscription[]>([])
  const ticketsObserver = useRef(false)
  // FIXME: Make user presence feature working properly.
  // const disconnectRef = useRef(null)

  useEffect(() => {
    if (disabled) {
      return
    }

    if (user) {
      // if (heap) heap.identify(user.uid)
      return
    }

    setLoadingAuthState(true)

    const auth = getAuth(firebaseApp)

    const unsubscribe = onAuthStateChanged(auth, async (dbUser) => {
      const userId = dbUser?.uid ?? null

      if (!userId || user) {
        setLoadingAuthState(false)
        return
      }

      // if (heap) heap.identify(userId)

      const userData = await loadUserData(dbUser)
      const currentUser = getAuth(firebaseApp).currentUser
      if (userData && currentUser) {
        const idTokenResult = await getIdTokenResult(currentUser)
        userData.admin = idTokenResult.claims.admin

        // FIXME: Make user presence feature working properly.
        // setUserOnline(userData)
        setUser(userData)
      }

      setLoadingAuthState(false)
    })
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [user])

  useEffect(() => {
    if (!user) {
      return
    }

    if (loginModal.isVisible && !loginModal.registering) {
      handleModalClose()
    }
  }, [user, loginModal])

  const loadUserData = async (dbUser) => {
    const database = getDatabase(firebaseApp)

    try {
      const [userSnapshot, ticketQuerySnapshot, isTestUserSnapshot] = await Promise.all([
        get(ref(database, `/users/${dbUser.uid}`)),
        get(ref(database, `/tickets/${dbUser.uid}`)),
        get(ref(database, `/test-users/${dbUser.uid}`)),
      ])

      if (!userSnapshot.exists()) {
        return null
      }

      const userData = userSnapshot.val()

      userData.uid = dbUser.uid
      userData.providerData = dbUser.providerData
      userData.emailVerified = dbUser.emailVerified
      userData.email = dbUser.email
      userData.admin = dbUser.admin
      userData.tickets = []
      userData.isTestUser = isTestUserSnapshot.exists()

      if (ticketQuerySnapshot.exists()) {
        const ticketsData = ticketQuerySnapshot.val()
        const tickets: any[] = ticketsData ? Object.values(ticketsData) : []
        const filteredData = tickets && tickets.length ? tickets.filter((ticket) => !!ticket.ticketId) : null
        if (filteredData) {
          userData.tickets = filteredData
        }
      }

      return userData
    } catch {
      return null
    }
  }

  // FIXME: Make user presence feature working properly.
  // const setUserOnline = async (userData) => {
  //   const connectionRef = getDatabase(firebaseApp).ref('/connected')
  //   const userRef = getDatabase(firebaseApp).ref(`/users/${userData.uid}/lastLogin`)
  //   const tickets = userData?.tickets || []
  //   const conferences = {}
  //   tickets.forEach(({ conferenceId }) => {
  //     conferences[conferenceId] = true
  //   })
  //
  //   getDatabase(firebaseApp).ref('.info/connected').on('value', (snapshot) => {
  //     if (snapshot.val() === false) {
  //       return
  //     }
  //
  //     const con = connectionRef.push()
  //
  //     disconnectRef.current = {
  //       allRef: con,
  //       myProfileRef: userRef
  //     }
  //
  //     con.onDisconnect().remove().then(() => {
  //       con.set({
  //         uid: userData.uid,
  //         conferences,
  //         lastChanged: new Date().getTime()
  //       })
  //     })
  //
  //     userRef.onDisconnect().set(new Date().toISOString()).then(() => userRef.set(0))
  //   })
  // }

  // Realtime observing for changes in tickets
  useEffect(() => {
    if (!user || ticketsObserver.current) {
      return
    }

    ticketsObserver.current = true

    const database = getDatabase(firebaseApp)
    const firebaseTicketsDBRef = ref(database, `/tickets/${user.uid}`)

    onValue(firebaseTicketsDBRef, (ticketQuerySnapshot) => {
      if (!ticketQuerySnapshot.exists()) {
        return
      }

      const ticketsData = ticketQuerySnapshot.val()
      const tickets: any[] = Object.values(ticketsData)
      const filteredData = tickets && tickets.length ? tickets.filter((ticket) => !!ticket.ticketId) : null

      if (filteredData && !ticketArrayEquals(user.tickets, filteredData)) {
        setUser((userData) => {
          return (
            userData && {
              ...userData,
              tickets: filteredData,
            }
          )
        })
      }
    })

    return () => {
      off(firebaseTicketsDBRef)
    }
  }, [user])

  const updateUser = (value) => {
    setUser((prev) => {
      return {
        ...prev,
        ...value,
      }
    })
  }

  const openLoginModal = (callback) => {
    setLoginModal({
      isVisible: true,
      registering: false,
      closeCallback: callback || (() => undefined),
    })
  }

  const setRegistering = (value) => {
    setLoginModal((prev) => ({
      ...prev,
      registering: value,
    }))
  }

  const handleModalClose = () => {
    if (user) {
      loginModal.closeCallback()
    }

    setLoginModal({
      isVisible: false,
      closeCallback: () => undefined,
      registering: false,
    })
  }

  const saveLogin: SaveFunction = async (res, email, userNameObject, jobTitle, company, country, state) => {
    const userInfo = getAdditionalUserInfo(res)

    if (userInfo?.isNewUser) {
      if (!res.user.email && email) {
        const currentUser = getAuth(firebaseApp).currentUser
        if (currentUser) {
          await updateEmail(currentUser, email)
        }
      }
      const user = {
        ...res.user,
        email: res.user.email || email,
      }

      ApiGateway.syncTicketFromFirestoreToFirebase(firebaseApp, user)
      ApiGateway.syncOrdersFromFirestoreToFirebase(firebaseApp, user)

      const userName = userNameObject || splitDisplayName(res.user.displayName)

      const userInformation = {
        photoURL: res.user.photoURL || '',
        ...userName,
        jobTitle: jobTitle || '',
        company: company || '',
        country: country || '',
        state: state || '',
        created: Date.now(),
        lastLogin: new Date().toISOString(),
      }

      // All data that will be hidden for public access, but useful for management.
      const userDetails = {
        email: res.user.email || email,
      }

      ApiGateway.saveUserDetails(firebaseApp, res.user.uid, userDetails)

      return set(ref(getDatabase(firebaseApp), `/users/${res.user.uid}`), userInformation).then(() => {
        setUser({
          ...userInformation,
          uid: res.user.uid,
          emailVerified: res.user.emailVerified,
          tickets: [],
          created: new Date().toISOString(),
        })
      })
    } else {
      const user = {
        ...res.user,
        email: res.user.email || email,
      }
      ApiGateway.syncTicketFromFirestoreToFirebase(firebaseApp, user)
      ApiGateway.syncOrdersFromFirestoreToFirebase(firebaseApp, user)
      await update(ref(getDatabase(firebaseApp), `/users/${res.user.uid}`), {
        lastLogin: new Date().toISOString(),
      })
    }
  }

  const loginWithToken = async (token) => {
    const auth = getAuth(firebaseApp)

    const res = await signInWithCustomToken(auth, token)
    await saveLogin(res)
  }

  const loginWithProvider = async (providerName) => {
    const provider = getProvider(providerName)

    if (!provider) {
      return
    }

    const auth = getAuth(firebaseApp)

    const res = await signInWithPopup(auth, provider)
    await saveLogin(res)
  }

  const loginWithEmail = async (email, password) => {
    const auth = getAuth(firebaseApp)

    const res = await signInWithEmailAndPassword(auth, email, password)
    await saveLogin(res)
  }

  const cancelActiveSubscriptions = () => {
    activeSubscriptions.current.forEach(({ subscription }) => {
      subscription.unsubscribe()
    })
  }

  const logout = () => {
    cancelActiveSubscriptions()

    // FIXME: Make user presence feature working properly.
    // if (disconnectRef.current && disconnectRef.current.allRef && disconnectRef.current.myProfileRef) {
    //   disconnectRef.current.allRef.remove()
    //   disconnectRef.current.allRef.onDisconnect().cancel()
    //
    //   disconnectRef.current.myProfileRef.set(new Date().toISOString())
    //   disconnectRef.current.myProfileRef.onDisconnect().cancel()
    // }

    return new Promise((resolve) => {
      const auth = getAuth(firebaseApp)
      signOut(auth).then(function () {
        setUser(null)
        resolve('Successfully logged out!')
      })
    })
  }

  const register = async (firstName, lastName, jobTitle, company, email, password, country, state) => {
    const auth = getAuth(firebaseApp)
    const res = await createUserWithEmailAndPassword(auth, email, password)

    await updateProfile(res.user, { displayName: `${firstName} ${lastName}` })
    sendEmailVerification(res.user)
    await saveLogin(res, email, { firstName, lastName }, jobTitle, company, country, state)
    setLoginModal((prev) => ({
      ...prev,
      registering: false,
    }))
  }

  const resetTestUserSessions = async () => {
    if (!user) {
      return
    }

    const allConferencesRegistrations = await ApiGateway.getUserRegistrations(firebaseApp, user.uid)

    allConferencesRegistrations.forEach((conferenceRegistrations) => {
      // @ts-ignore
      const registrations: any[] = conferenceRegistrations ? Object.values(conferenceRegistrations) : []

      registrations.forEach(async (registrationItem) => {
        if (registrationItem.registration?.status === 'registered') {
          const transactionCompleted = await ApiGateway.unregisterFromSession(
            firebaseApp,
            registrationItem.sessionId,
          )

          if (transactionCompleted) {
            await ApiGateway.removeUserRegistration(
              firebaseApp,
              user.uid,
              registrationItem.productId,
              registrationItem.sessionId,
            )
          }
        } else if (registrationItem.registration?.status === 'waiting') {
          await ApiGateway.unregisterFromWaitingList(firebaseApp, registrationItem.sessionId, user.uid)
          await ApiGateway.removeUserRegistration(
            firebaseApp,
            user.uid,
            registrationItem.productId,
            registrationItem.sessionId,
          )
        }
      })
    })
    await setTestUserSessionLimit()
  }

  const setTestUserSessionLimit = async (value = 2) => {
    if (!user) {
      return
    }

    const ticket = user?.tickets?.[0] ?? null
    const ticketDbId = ticket ? `${ticket.productId}${ticket.ticketId}` : null

    if (!ticketDbId) {
      return
    }

    await update(ref(getDatabase(firebaseApp), `/tickets/${user.uid}/${ticketDbId}`), {
      sessionLimit: value,
    })
  }

  const forgotPassword = async function (email) {
    const auth = getAuth(firebaseApp)
    await sendPasswordResetEmail(auth, email.toLowerCase())
  }

  const reauthenticate = async (email, password) => {
    const credential = createCredentials(email, password)

    const dbUser = getAuth(firebaseApp).currentUser

    if (!dbUser) {
      return
    }

    return await reauthenticateWithCredential(dbUser, credential)
      .then((data) => ({
        code: 200,
        user: data.user,
      }))
      .catch((error) => error)
  }

  const updateUserPassword = async (dbUser, newPassword) => {
    return await updatePassword(dbUser, newPassword)
      .then(() => true)
      .catch(() => false)
  }

  const addActiveSubscription = (id, subscription) => {
    activeSubscriptions.current.push({
      id,
      subscription,
    })
  }

  const removeActiveSubscriptions = (ids) => {
    activeSubscriptions.current = activeSubscriptions.current.filter(
      (subscription) => !ids.includes(subscription.id),
    )
  }

  const getOrders = async () => {
    if (!user) {
      return
    }
    const orderIdsSnaphot = await get(ref(getDatabase(firebaseApp), `/orders/userOrders/${user.uid}`))
    const orderIds = orderIdsSnaphot.val()
    // hydrate the orders
    const promises: Promise<DataSnapshot>[] = []
    if (orderIds) {
      Object.values(orderIds).forEach((orderId) => {
        promises.push(get(ref(getDatabase(firebaseApp), `/orders/all/${orderId}`)))
      })
      const orders = await Promise.all(promises)
      const hydratedOrders = orders.map((order) => order.val())
      return hydratedOrders
    }
    return
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        authenticated: user !== null,
        loadingAuthState,
        isVerified: user && typeof user.emailVerified === 'boolean' && user.emailVerified,
        logout,
        register,
        resetTestUserSessions,
        setTestUserSessionLimit,
        loginWithProvider,
        loginWithToken,
        loginWithEmail,
        forgotPassword,
        openLoginModal,
        setRegistering,
        handleModalClose,
        loginModal,
        reauthenticate,
        updatePassword: updateUserPassword,
        addActiveSubscription,
        removeActiveSubscriptions,
        getOrders,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthService = () => {
  const authService = useContext(AuthContext)

  if (!authService) {
    throw 'Auth context is not provided!'
  }

  return authService
}

function ticketArrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every(
      (val, index) =>
        val?.ticketId === b[index]?.ticketId &&
        val?.productId === b[index]?.productId &&
        val?.email === b[index]?.email,
    )
  )
}
