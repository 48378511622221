import { AuthUser, Ticket } from 'theme/services/auth/auth-service.interfaces'

type UserName = {
  firstName: string
  lastName: string
}

type ConferenceTicketsResults = {
  tickets: Ticket[]
  workshopTickets: Ticket[]
  inPersonTickets: Ticket[]
}

export const isBrowser = function () {
  return typeof window !== 'undefined'
}

/// ///////////////////////////////////////////////
/// //// LINKED LOGIN ////////////////////////////
/// //////////////////////////////////////////////

export const loginLinkedin = () => {
  const STATE = 'fdsf78fyds7fm'
  const getLinkedInClientId = () => {
    if (!process.env.GATSBY_LINKEDIN_CLIENT_ID) {
      throw 'GATSBY_LINKEDIN_CLIENT_ID not provided'
    }

    return process.env.GATSBY_LINKEDIN_CLIENT_ID
  }

  const getFirebaseProjectId = () => {
    if (!process.env.GATSBY_FIREBASE_PROJECT_ID) {
      throw 'GATSBY_FIREBASE_PROJECT_ID not provided'
    }

    return process.env.GATSBY_FIREBASE_PROJECT_ID
  }

  const getFirebaseLinkedInPopupRedirect = () => {
    if (!process.env.GATSBY_LINKEDIN_POPUP_REDIRECT) {
      throw 'GATSBY_LINKEDIN_POPUP_REDIRECT not provided'
    }

    return process.env.GATSBY_LINKEDIN_POPUP_REDIRECT
  }

  const getToken = async (code) => {
    try {
      const endPoint = `https://us-central1-${getFirebaseProjectId()}.cloudfunctions.net/login-login?code=${encodeURIComponent(
        code,
      )}&state=${encodeURIComponent(STATE)}&redirect=${encodeURIComponent(
        getFirebaseLinkedInPopupRedirect(),
      )}`
      const response = await fetch(endPoint)
      return response.json()
    } catch (error) {
      return error
    }
  }

  return {
    getLinkedInClientId,
    getToken,
    getFirebaseLinkedInPopupRedirect,
    STATE,
  }
}

export const checkAvailability = (user, publicReleaseDate, sessionType, conferenceId) => {
  const { tickets, workshopTickets } = getConferenceTickets(user, conferenceId)

  // TODO: remove the magic workshop variable - ok for now since workshop is our only exception but won't always be the case
  return {
    loggedIn: !!user,
    verified: user && user.emailVerified,
    public: publicReleaseDate && new Date(publicReleaseDate[0]).getTime() < Date.now(),
    ticket:
      (sessionType === 'Workshop' && workshopTickets.length > 0) ||
      (sessionType !== 'Workshop' && tickets.length > 0),
  }
}

export const checkUserConferenceAccess = (user, conferenceId) => {
  if (!conferenceId) return false
  const isCommunity = conferenceId.includes('community')
  const { tickets } = getConferenceTickets(user, conferenceId)

  return user && (isCommunity || tickets.length > 0)
}

export const getConferenceTickets = (user: AuthUser, conferenceId): ConferenceTicketsResults => {
  const results: ConferenceTicketsResults = {
    tickets: [],
    workshopTickets: [],
    inPersonTickets: [],
  }

  if (!user?.tickets) {
    return results
  }

  results.tickets = user.tickets.filter((ticket) => {
    if (ticket.productId === conferenceId) {
      return true
    }
  })

  results.workshopTickets = results.tickets.filter((ticket) => {
    if (ticket.ticketType?.indexOf('Workshop') !== -1) {
      return true
    }
  })

  results.inPersonTickets = results.tickets.filter((ticket) => {
    if (ticket?.delivery?.toLowerCase().indexOf('in-person') !== -1) {
      return true
    }
  })

  return results
}

export const splitDisplayName = (displayName: string | null): UserName => {
  const results = {
    firstName: '',
    lastName: '',
  }

  if (!displayName) {
    return results
  }

  const names = displayName.trim().split(' ')

  switch (names.length) {
    case 0: //will not happen, even for empty string
    case 1: //only first name provided (or blank)
      results.firstName = names[0]
      break
    case 2:
      results.firstName = names[0]
      results.lastName = names[1]
      break
    default:
      // > 2 length - first item is the first name. last item is the last name. Everything else are middle names
      for (let i = 0; i < names.length - 1; i++) {
        if (i === 0) {
          results.firstName = names[i]
        } else {
          if (!results.lastName) results.lastName = names[i]
          else results.lastName += ` ${names[i]}`
        }
      }
      break
  }

  return results
}
