import Message from 'src/chat/model/message'

interface IConversationListItem {
  getMessage(): Message
  getUnreadCount(): number
  getUid(): string
}

class ConversationListItem implements IConversationListItem {
  lastMessage: Message
  unreadCount: number
  uid: string

  constructor(lastMessage: Message, unreadCount: number, uid: string) {
    this.lastMessage = lastMessage
    this.unreadCount = unreadCount
    this.uid = uid
  }

  getMessage() {
    return this.lastMessage
  }

  getUnreadCount() {
    return this.unreadCount
  }

  getUid() {
    return this.uid
  }
}

export default ConversationListItem
