interface IUser {
  getPhotoURL(): string
  isOnline(): boolean
}

class User implements IUser {
  firstName: string
  lastName: string
  uid: string
  lastLogin: string | number
  photoURL?: string
  email?: string
  created?: string
  jobTitle?: string
  company?: string
  linkedin?: string
  twitter?: string
  about?: string
  calendly?: string
  claims?: any

  constructor(
    uid: string,
    firstName: string,
    lastName: string,
    lastLogin: string | number,
    photoURL?: string,
    // email?: string,
    created?: string,
    jobTitle?: string,
    company?: string,
    linkedin?: string,
    twitter?: string,
    about?: string,
    calendly?: string,
    claims?: object,
  ) {
    this.uid = uid
    this.firstName = firstName
    this.lastName = lastName
    this.jobTitle = jobTitle
    this.company = company
    this.linkedin = linkedin
    this.twitter = twitter
    this.about = about
    this.calendly = calendly
    this.claims = claims
    this.lastLogin = lastLogin

    // if (email) {
    //   this.email = email
    // }

    if (photoURL) {
      this.photoURL = photoURL
    }

    if (created) {
      this.created = created
    }
  }

  getPhotoURL(): string {
    return this.photoURL || ''
    // return this.photoURL || ProfileImagePlaceholder;
  }

  isOnline(): boolean {
    return this.lastLogin === 0
  }
}

export default User
