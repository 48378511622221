export const getTwitterHandle = (url) => {
  const firstChar = url.charAt(0)
  if (firstChar === '@') return url
  else {
    // if the last character is / then remove it
    if (url.lastIndexOf('/') === url.length - 1) url = url.substring(0, url.length - 1)
    let handle = url.substring(url.lastIndexOf('/') + 1)
    if (handle.indexOf('?') !== -1) handle = handle.substring(0, handle.indexOf('?'))
    return '@' + handle
  }
}

export const getTwitterUrl = (url) => {
  const firstChar = url.charAt(0)
  if (firstChar === '@') {
    const handle = url.substring(1, url.length)
    return 'https://twitter.com/' + handle
  } else return url
}

export const youtubeParser = (url) => {
  // Updated regular expression to differentiate between query parameters and youtu.be format
  var regExp = /^.*(?:youtu.be\/|youtube.com\/(?:embed\/|(?:watch)?\?(?:.*&)?v(?:i)?=))([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[1].length === 11 ? match[1] : false
}
