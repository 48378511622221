import React, { useRef, useContext, createContext, useState, useEffect } from 'react'
import ChatDatabase from 'src/chat/database/chat-database'
import { useFirebaseService } from 'theme/services/firebase-service'
import UserDatabase from 'src/chat/database/users-database'
import User from 'src/model/user'
import ProfileModal from 'src/components/modals/profileModal'
import { IChatService } from 'src/chat/service/chat-service.interfaces'

export const ChatContext = createContext<IChatService | null>(null)

const ChatProvider = ({ children }: React.HTMLProps<HTMLDivElement>) => {
  const firebaseApp = useFirebaseService()
  const chatDatabase = useRef<ChatDatabase | null>(null)
  const userDatabase = useRef<UserDatabase | null>(null)
  const [users, setUsers] = useState({})
  const [activeConversation, setActiveConversation] = useState<User | null>(null)
  const [previewProfile, setPreviewProfile] = useState<User | null>(null)

  useEffect(() => {
    chatDatabase.current = new ChatDatabase(firebaseApp)
    userDatabase.current = new UserDatabase(firebaseApp)
  }, [])

  const getUser = async (uid) => {
    if (!userDatabase.current) {
      return Promise.reject()
    }

    return userDatabase.current.getUser(uid)
  }

  const observeNewMessages = (self, destination, key) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.observeNewMessages(self, destination, key)
  }

  const loadOldMessage = async (self, destination, key) => {
    if (!chatDatabase.current) {
      return Promise.reject()
    }

    return chatDatabase.current.getOldMessages(self, destination, key)
  }

  const sendMessage = (self, destination, message, isChatEnabled) => {
    if (!chatDatabase.current) {
      return null
    }

    if (!message.id) delete message.id
    if (!message.destination) delete message.destination

    chatDatabase.current.sendMessage(self, destination, message, isChatEnabled)
  }

  const deleteChatMessage = (roomId, messageId) => {
    chatDatabase.current?.deleteMessage(roomId, messageId)
  }

  const pinChatMessage = (roomId, messageId) => {
    chatDatabase.current?.pinMessage(roomId, messageId)
  }

  const removePinnedMessage = (roomId) => {
    chatDatabase.current?.removePinnedMessage(roomId)
  }

  const observeTyping = (self, destination) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.observeTyping(self, destination)
  }

  const setTyping = (self, destination, value) => {
    chatDatabase.current?.setTyping(self, destination, value)
  }

  const getTypingObserver = (self, destination) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.getTypingObserver(self, destination)
  }

  const observeConversationsFor = (self) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.observeConversationsFor(self)
  }

  const loadRoomChat = async (roomName, key) => {
    if (!chatDatabase.current) {
      return Promise.reject()
    }

    return chatDatabase.current.getRoomChat(roomName, key)
  }

  const sendRoomMessage = (roomName, message) => {
    chatDatabase.current?.sendRoomMessage(roomName, message)
  }

  const observeRoomNewMessages = (roomName) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.observeRoomNewMessages(roomName)
  }
  const observeRoomDeletedMessages = (roomName) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.observeRoomDeletedMessages(roomName)
  }

  // FIXME: Make user presence feature working properly.
  // const observeConnectedUsers = (uid, confId) => {
  //   return userDatabase.current.observeConnectedUsers(uid, confId)
  // }

  const observePinnedMessage = (roomName) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.observePinnedMessage(roomName)
  }

  const getConferenceUsers = (confId, lastDoc, limit) => {
    if (!userDatabase.current) {
      return Promise.reject()
    }

    return userDatabase.current.getConferenceUsers(confId, lastDoc, limit)
  }

  const addUsers = (newUsers) => {
    setUsers((prevUsers) => {
      return {
        ...prevUsers,
        ...newUsers,
      }
    })
  }

  const toggleChat = (destination) => {
    if (activeConversation?.uid === destination?.uid) {
      return
    }

    setActiveConversation(destination)
  }

  const showProfile = (destination) => {
    if (previewProfile?.uid === destination?.uid) {
      return
    }

    setPreviewProfile(destination)
  }

  const observeUserOnline = (uid) => {
    if (!userDatabase.current) {
      return null
    }

    return userDatabase.current.observeUserOnline(uid)
  }

  const getDestinationChatEnabled = (self, destination) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.observeDestinationChat(self, destination)
  }

  const setChatEnabled = (self, destination, value) => {
    if (!chatDatabase.current) {
      return null
    }

    return chatDatabase.current.setChatEnabled(self, destination, value)
  }

  const nullifyUnreadCount = (self, destination) => {
    chatDatabase.current?.nullifyUnreadCount(self, destination)
  }

  const publicMethods = {
    activeConversation,
    users,
    addUsers,
    getUser,
    loadOldMessage,
    observeUserOnline,
    observeNewMessages,
    observeTyping,
    setTyping,
    getTypingObserver,
    sendMessage,
    deleteChatMessage,
    pinChatMessage,
    removePinnedMessage,
    observeConversationsFor,
    loadRoomChat,
    sendRoomMessage,
    observeRoomNewMessages,
    observeRoomDeletedMessages,
    // FIXME: Make user presence feature working properly.
    // observeConnectedUsers,
    observePinnedMessage,
    getConferenceUsers,
    toggleChat,
    showProfile,
    getDestinationChatEnabled,
    setChatEnabled,
    nullifyUnreadCount,
  }

  return (
    <ChatContext.Provider value={publicMethods}>
      {children}
      <ProfileModal
        isOpen={previewProfile !== null}
        profile={previewProfile}
        onClose={() => setPreviewProfile(null)}
      />
    </ChatContext.Provider>
  )
}

export default ChatProvider

export const useChatService = () => {
  const chatService = useContext(ChatContext)

  if (!chatService) {
    throw 'Chat service not provided!'
  }

  return chatService
}
