import React, { useEffect, useState, useContext, useRef } from 'react'
import { useFirebaseService } from 'theme/services/firebase-service'
import { useAuthService } from 'theme/services/auth/auth-service'
import { getDatabase, update, onValue, ref, off } from 'firebase/database'

interface TimezoneService {
  timezone: string
  saveTimezone: (timezone: string) => void
  isReadFromDB: () => boolean
}

export const TimezoneContext = React.createContext<TimezoneService | null>(null)

export const TimezoneProvider = ({ children }) => {
  const firebaseApp = useFirebaseService()
  const user = useAuthService()?.user
  const [timezone, setTimezone] = useState(() => Intl.DateTimeFormat().resolvedOptions().timeZone)
  const dBRead = useRef(false)

  useEffect(() => {
    if (!user) {
      return
    }

    const firebaseUsersDBRef = ref(getDatabase(firebaseApp), `/users/${user.uid}`)

    onValue(firebaseUsersDBRef, (snapshot) => {
      dBRead.current = true
      const userData = snapshot.val()
      if (!userData.timezone) {
        saveTimezone(timezone)
      } else {
        setTimezone(userData.timezone)
      }
    })

    return () => {
      if (firebaseUsersDBRef) {
        off(firebaseUsersDBRef)
      }
    }
  }, [user])

  const saveTimezone = (timezone) => {
    setTimezone(timezone)

    if (!user) {
      return
    }

    update(ref(getDatabase(firebaseApp), `/users/${user.uid}`), { timezone })
  }

  const isReadFromDB = () => {
    return !!dBRead.current
  }

  return (
    <TimezoneContext.Provider
      value={{
        timezone,
        saveTimezone,
        isReadFromDB,
      }}
    >
      {children}
    </TimezoneContext.Provider>
  )
}

export const useTimezoneService = () => {
  return useContext(TimezoneContext)
}
