interface MessageProps {
  id?: string
  sender: string
  destination?: string
  message: string
  timestamp?: number
}

export interface IMessage extends MessageProps {
  getID(): string
  getSender(): string
  getDestination(): string
  getMessage(): string
  getTimestamp(): number
}

class Message implements IMessage {
  id?: string
  destination?: string
  sender: string
  message: string
  timestamp: number

  constructor({ id, sender, destination, message, timestamp = new Date().getTime() }: MessageProps) {
    this.sender = sender
    this.message = message
    this.timestamp = timestamp

    if (id) {
      this.id = id
    } else {
      delete this.id
    }

    if (destination) {
      this.destination = destination
    } else delete this.destination
  }

  getID() {
    return this.id || ''
  }

  getSender() {
    return this.sender
  }

  getDestination() {
    return this.destination || ''
  }

  getMessage() {
    return this.message
  }

  getTimestamp() {
    return this.timestamp
  }
}

export default Message
