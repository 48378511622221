import { off, set, onDisconnect } from 'firebase/database'

export interface IObservable<T> {
  emit(value: T): void
  subscribe(subscription: (value: T) => void): void
  unsubscribe(): void
}

class Observable<T> implements IObservable<T> {
  subscriptions: ((value: T) => void)[]
  dbRef: any
  onDisconnect: any

  constructor(dbRef: any) {
    this.subscriptions = []
    this.dbRef = dbRef
    this.onDisconnect = null
  }

  setOnDisconnect(value) {
    onDisconnect(this.dbRef).set(value)
    this.onDisconnect = value
  }

  subscribe(subscription: (value: T) => void) {
    this.subscriptions.push(subscription)
  }

  emit(value: T) {
    this.subscriptions.forEach((subscription) => {
      subscription(value)
    })
  }

  unsubscribe(): void {
    if (this.onDisconnect !== null) {
      set(this.dbRef, this.onDisconnect)
      onDisconnect(this.dbRef).cancel()
    } else if (this.dbRef) {
      off(this.dbRef)
    }
  }
}

export default Observable
