import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useSyncExternalStore,
} from 'react'

interface GlobalStore {
  pagesData: any
}

export const GlobalContext = createContext<{
  get: () => GlobalStore
  set: (value: Partial<GlobalStore>) => void
  subscribe: (callback: () => void) => () => void
} | null>(null)

const initialStore: GlobalStore = {
  pagesData: {},
}

export const GlobalServiceProvider: FC<PropsWithChildren> = ({ children }) => {
  const store = useRef(initialStore)
  const subscribers = useRef(new Set<() => void>())

  const get = useCallback(() => store.current, [])

  const set = useCallback((value: Partial<GlobalStore>) => {
    store.current = { ...store.current, ...value }
    subscribers.current.forEach((callback) => callback())
  }, [])

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback)
    return () => subscribers.current.delete(callback)
  }, [])

  useEffect(() => {
    const exampleRecipient = {
      recipientEmail: 'rory.madden@uxdx.com',
    }

    const examples = {
      orderCompleted: {
        ...exampleRecipient,
        subject: 'Test for orderCompleted template',
        templateName: 'orderCompleted',
        payload: {
          ticketCounts: [
            {
              ticketType: 'Talks in Person',
              quantity: 12,
              currencyPaid: '$123',
            },
          ],
          orderId: '1234',
          productName: 'My product name',
          startAndEndDates: '01.01.2024',
          currencyPaid: '$123',
          currencyTax: '$0',
          // receiptUrl: 'https://www.google.com',
        },
      },
      ticketCompleted: {
        ...exampleRecipient,
        templateName: 'ticketCompleted',
        subject: 'Test for ticketCompleted template',
        payload: {
          uid: 'ticket.uid',
          ticketId: 'ticket.ticketId',
          firstName: 'ticket.user.firstName',
          lastName: 'ticket.user.lastName',
          jobTitle: 'ticket.user.jobTitle',
          company: 'ticket.user.company',
          ticketType: 'ticket.delivery + ticket.ticketType',
          ticketsLink: "getEventLinkByType(ticket.productId, ticket.productType, 'tickets')",
          agendaLink: "getEventLinkByType(ticket.productId, ticket.productType, 'agenda')",
          workshopsAvailableButNotIncluded: true,
          startAndEndDate: 'startAndEndDate(ticket.product.startDate, ticket.product.endDate)',
          productName: 'ticket.productName',
          qrCode: 'https://storage.googleapis.com/uxdx-ticket-qrcodes/community-apac-2023-02-028Q3EU29T-1',
          // hotels: [
          //   {
          //     Name: 'Civilian Hotel',
          //     Address: '305 W 48th St, New York, NY 10036',
          //     DiscountInstructions:
          //       'Click this link to get discounted room rates at the Civilian hotel, hotel rooms from $416 a night.',
          //     DiscountCode: 'UXDX24',
          //     ImageURL: 'https://storage.googleapis.com/uxdx-promotion-image/recuAgnsMB9oATrE9',
          //     BookingLink: 'https://uxdx.com',
          //   },
          // ],
        },
      },
      ticketAssigned: {
        ...exampleRecipient,
        templateName: 'ticketAssigned',
        subject: 'Test for ticketAssigned template',
        payload: {
          ticketRef: 'l;fhasdl;kfhsdjhfsad;lkjfhas;ldkfjd;lskjk;jsdf',
          ticketType: 'Talks - Online',
          productName: 'UXDX Community - Barcelona: Thoughts and Prayers',
        },
      },
      ticketUnassigned: {
        ...exampleRecipient,
        templateName: 'ticketUnassigned',
        subject: 'Test for ticketUnassigned template',
        payload: {
          ticketRef: 'l;fhasdl;kfhsdjhfsad;lkjfhas;ldkfjd;lskjk;jsdf',
          ticketType: 'Talks - Online',
          productName: 'UXDX Community - Barcelona: Thoughts and Prayers',
        },
      },
      ticketAssignedNoAccount: {
        ...exampleRecipient,
        templateName: 'ticketAssigned',
        subject: 'Test for ticketAssigned template',
        payload: {
          ticketRef: 'My ticketRef',
          ticketType: 'My ticketType',
          productName: 'My productName',
        },
      },
      sessionRegistered: {
        ...exampleRecipient,
        templateName: 'sessionRegistered',
        subject: 'Test for sessionRegistered template',
        payload: {
          productName: 'productName',
          sessionName: 'sessionName',
          sessionStartTime: new Date().toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'UTC',
          }),
          sessionLink: 'sessionLink',
        },
      },
      sessionUnregistered: {
        ...exampleRecipient,
        templateName: 'sessionUnregistered',
        subject: 'Test for sessionUnregistered template',
        payload: {
          productName: 'productName',
          sessionName: 'sessionName',
          productId: 'productId',
          productType: 'productType',
          agendaLink: 'agendaLink',
        },
      },
      sessionWaiting: {
        ...exampleRecipient,
        templateName: 'sessionWaiting',
        subject: 'Test for sessionWaiting template',
        payload: {
          productName: 'productName',
          sessionName: 'sessionName',
          productId: 'productId',
          productType: 'productType',
          agendaLink: 'agendaLink',
        },
      },
      chaseOrderAssigns: {
        ...exampleRecipient,
        templateName: 'chaseOrderAssigns',
        subject: 'Test for chaseOrderAssigns template',
        payload: {
          orderId: 'usa-2024FDKU72MP',
          productName: 'UXDX USA 2024',
        },
      },
      chaseTicketAccounts: {
        ...exampleRecipient,
        templateName: 'chaseTicketAccounts',
        subject: 'Test for chaseTicketAccounts template',
        payload: {
          productName: 'UXDX USA 2024',
          startAndEndDates: '15 - 17 May 2024',
          ticketType: 'Online Talks',
          ticketRef: 'usa-2024FDKU72MP-1',
        },
      },
    }

    // Just to test sending email

    // fetch('https://us-central1-uxdx-48a34.cloudfunctions.net/mailersend-sendMail', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(examples.chaseOrderAssigns),
    // })
    //   .then(console.log)
    //   .catch(console.log)
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        get,
        set,
        subscribe,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalService = <SelectorOutput extends unknown>(
  selector: (store: GlobalStore) => SelectorOutput,
) => {
  const store = useContext(GlobalContext)

  if (!store) {
    throw new Error('Global store not found')
  }

  const state = useSyncExternalStore(
    store.subscribe,
    () => selector(store.get()),
    () => selector(initialStore),
  )

  return [state, store.set]
}
