import User from 'src/model/user'
import Observable from 'src/chat/model/observable'
import { getDatabase, ref, get, query, orderByChild, startAt, endAt, onValue } from 'firebase/database'

interface IUserDatabase {
  getUser(uid: string): Promise<User | null>
  // FIXME: Make user presence feature working properly.
  // observeConnectedUsers (uid: string, confId: string): Observable<string[]>
  observeUserOnline(uid: string): Observable<boolean>
  getConferenceUsers(
    confId: string,
    lastDoc: any,
    limit: number,
  ): Promise<{ uids: string[]; lastDoc: any; limit: number }>
}

class UserDatabase implements IUserDatabase {
  firebaseDb: any
  // firestoreDb: any

  constructor(firebaseApp) {
    this.firebaseDb = getDatabase(firebaseApp)
    // this.firestoreDb = firebase.firestore()
  }

  async getUser(uid: string) {
    try {
      const userSnap = await get(ref(this.firebaseDb, `/users/${uid}`))

      if (!userSnap.exists()) {
        return null
      }

      const userUid = userSnap.key

      if (!userUid) {
        return null
      }

      const userObject = userSnap.val()

      // split userOnkect.display name into first and last name
      const displayName = userObject.displayName
      let firstName, lastName
      if (displayName) {
        const displayNameArray = displayName.split(' ')
        firstName = displayNameArray[0]
        displayNameArray.shift()
        lastName = displayNameArray.join(' ')
      }

      return new User(
        userUid,
        userObject.firstName || firstName,
        userObject.lastName || lastName,
        userObject.lastLogin,
        userObject.photoURL,
        // userObject.email,
        userObject.created,
        userObject.jobTitle,
        userObject.company,
        userObject.linkedin,
        userObject.twitter,
        userObject.about,
        userObject.calendly,
        userObject.claims,
      )
    } catch {
      return Promise.reject()
    }
  }

  async getConferenceUsers(
    confId: string,
    lastDoc: any,
    limit: number,
  ): Promise<{ uids: string[]; lastDoc: any; limit: number }> {
    const ticketsObject = await get(
      query(
        ref(this.firebaseDb, '/chatTickets/'),
        ...[orderByChild('conferenceId'), startAt(confId), endAt(confId)],
      ),
    )
    const tickets = Object.values(ticketsObject.val())
    // @ts-ignore
    const confUsersUids = tickets.map((ticket) => ticket.uid)

    return {
      uids: confUsersUids,
      lastDoc: tickets[tickets.length - 1],
      limit: limit,
    }

    // let requestBuilder = this.firestoreDb
    //   .collection('tickets')
    //   .where('conferenceId', '==', confId)
    //   .where('uid', '!=', null)

    // if (lastDoc !== null) {
    //   requestBuilder = requestBuilder.startAt(lastDoc)
    // }

    // if(limit) requestBuilder = requestBuilder.limit(limit).get()
    // else requestBuilder = requestBuilder.get()

    // const conferenceUsersCollection = await requestBuilder

    // let confUsersUids = []

    // if (conferenceUsersCollection.docs.length) {
    //   confUsersUids = conferenceUsersCollection.docs.map((ticketDoc) => {
    //     const ticket = ticketDoc.data()
    //     return ticket.uid
    //   })
    // }

    // return {
    //   uids: confUsersUids,
    //   lastDoc: conferenceUsersCollection.docs[conferenceUsersCollection.docs.length - 1],
    //   limit: limit
    // }
  }

  // FIXME: Make user presence feature working properly.
  // observeConnectedUsers(uid: string, confId: string): Observable<string[]> {
  //   const connectedUsersRef = this.firebaseDb.ref(`/connected`)
  //     .orderByChild(`conferences/${confId}`)
  //     .equalTo(true)
  //
  //   const observer = new Observable<string[]>(connectedUsersRef)
  //
  //   connectedUsersRef.on('value', (snapshot) => {
  //     const onlineUsersVal = snapshot.exists() ? snapshot.val() : false
  //     const allOnlineUsers: any = onlineUsersVal ? Object.values(onlineUsersVal) : []
  //
  //     const users = allOnlineUsers
  //       .filter((record) => record.uid !== uid && new Date().getTime() - record.lastChanged < (3 * 60 * 60 * 1000) )
  //       //Consider removing false positives values from DB
  //       .map((onlineUser) => onlineUser.uid)
  //
  //
  //     // During dev time firebase presence often not working proper due to localhost server, hot reloading and so on
  //     // Let's add something like auto clean our zombie records
  //     const myBuggedKeys = []
  //     if (onlineUsersVal) {
  //       for (const property in onlineUsersVal) {
  //         const user = onlineUsersVal[property]
  //         if (user.uid === uid && new Date().getTime() - user.lastChanged > (3* 60 * 60 * 1000)) {
  //           myBuggedKeys.push(property)
  //         }
  //       }
  //
  //       if (myBuggedKeys.length) {
  //         // Remove zombie records
  //         myBuggedKeys.forEach((key) => this.firebaseDb.ref(`/connected/${key}`).remove())
  //       }
  //     }
  //
  //     observer.emit(users)
  //
  //   }, (error) => {
  //     console.error(error)
  //   })
  //
  //   return observer
  // }

  observeUserOnline(uid: string): Observable<boolean> {
    const userRef = ref(this.firebaseDb, `/users/${uid}/lastLogin`)
    const observer = new Observable<boolean>(userRef)

    onValue(userRef, (snapshot) => {
      const onlineUsersVal = snapshot.exists() ? snapshot.val() : false

      observer.emit(onlineUsersVal === 0)
    })

    return observer
  }
}

export default UserDatabase
