import './src/css/style.css'
import { Cookies } from "react-cookie-consent";

const loadGTM = () => {
  if (!document.getElementById('gtm-script')) {
    const script = document.createElement('script');
    script.id = 'gtm-script';
    script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-TLSPK2H`;
    script.async = true;
    document.head.appendChild(script);
  }
};

const pushToDataLayer = (event, maxRetries = 10) => {
  if (window.dataLayer) {
    window.dataLayer.push({ event });
  } else if (maxRetries > 0) {
    setTimeout(() => pushToDataLayer(event, maxRetries - 1), 500);
  } else {
    console.error(`dataLayer is not defined, unable to push event: ${event}`);
  }
};

export const onClientEntry = () => {
  const consent = localStorage.getItem("gatsby-gdpr-google-tagmanager");
  if (consent) {
    const consentObj = JSON.parse(consent);
    loadGTM();

    if (consentObj.ads) {
      pushToDataLayer("ad_personalization_consent");
    }

    if (consentObj.analytics) {
      pushToDataLayer("analytics_storage_consent");
    }

    if (consentObj.functionality) {
      pushToDataLayer("functionality_storage_consent");
    }
  }
};

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     'This application has been updated. ' +
//       'Reload to display the latest version?'
//   )
//   if (answer === true) {
//     window.location.reload()
//   }
// }
