import React from 'react'
import PropTypes from 'prop-types'
import { AuthProvider } from 'theme/services/auth/auth-service'
import { TimezoneProvider } from 'theme/services/timezoneService'
import { ManagementServiceProvider } from 'src/services/management-service'
import { GlobalServiceProvider } from 'theme/services/global-service'
import ChatProvider from 'src/chat/service/chat-service'
import { FirebaseServiceProvider } from 'theme/services/firebase-service'
import ErrorBoundary from 'src/components/dev/errorBoundary'

const MainLayout = ({ children }) => {
  return (
    <ErrorBoundary>
      <GlobalServiceProvider>
        <FirebaseServiceProvider>
          <AuthProvider>
            <ManagementServiceProvider>
              <TimezoneProvider>
                <ChatProvider>{children}</ChatProvider>
              </TimezoneProvider>
            </ManagementServiceProvider>
          </AuthProvider>
        </FirebaseServiceProvider>
      </GlobalServiceProvider>
    </ErrorBoundary>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
