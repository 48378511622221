import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  EmailAuthProvider,
  getAuth,
  sendEmailVerification as firebaseSendEmailVerification,
} from 'firebase/auth'
import { AuthProvider, EmailAuthCredential } from '@firebase/auth'
import { FirebaseApp } from '@firebase/app'
import { Timestamp } from '@firebase/firestore'
import React, { createContext, useContext, useMemo } from 'react'

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

const FirebaseContext = createContext<FirebaseApp | null>(null)

export const FirebaseServiceProvider = ({ children }) => {
  const firebaseApp = useMemo(() => initializeApp(config), [])

  return <FirebaseContext.Provider value={firebaseApp}>{children}</FirebaseContext.Provider>
}

export const useFirebaseService = () => {
  const context = useContext(FirebaseContext)

  if (context === null) {
    throw 'Firebase Context is not initialized!'
  }

  return context
}

export const getProvider = (providerName: string): AuthProvider | null => {
  switch (providerName) {
    case 'google':
      return new GoogleAuthProvider()
    case 'twitter':
      return new TwitterAuthProvider()
    default:
      return null
  }
}

export const createCredentials = (email, password): EmailAuthCredential => {
  return EmailAuthProvider.credential(email, password)
}

export const sendEmailVerification = async (firebaseApp) => {
  const currentUser = getAuth(firebaseApp).currentUser

  if (!currentUser) {
    return Promise.reject()
  }

  return await firebaseSendEmailVerification(currentUser)
}

export const getFirestoreTimestamp = () => {
  return Timestamp.fromDate(new Date())
}
