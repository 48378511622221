import { useRef } from 'react'

interface Job {
  request: (...params: any) => Promise<any>
  requestParams: Array<any>
  callback: (item: any) => void
}

interface Task {
  id: string
  job: Job
}

export const useAsyncRequestQueue = () => {
  let taskQueue: Task[] = []
  const freeRequestQueue = useRef<boolean>(true)

  const addTask = (id: string, job: Job) => {
    const isAlreadyInQueue = taskQueue.find((task) => task.id === id)

    if (isAlreadyInQueue) {
      return
    }

    taskQueue = [
      ...taskQueue,
      {
        id,
        job,
      },
    ]

    runQueue()
  }

  const removeTask = (id) => {
    taskQueue = taskQueue.filter((task) => task.id !== id)
    freeRequestQueue.current = true
    runQueue()
  }

  const runQueue = () => {
    if (!taskQueue.length || !freeRequestQueue.current) {
      return
    }

    freeRequestQueue.current = false

    const currentTask = taskQueue[0]

    try {
      // @ts-ignore
      currentTask.job.request(...currentTask.job.requestParams).then((data) => {
        currentTask.job.callback(data)
        removeTask(currentTask.id)
      })
    } catch {
      removeTask(currentTask.id)
    }
  }

  return {
    addTask,
  }
}
