import Message from 'src/chat/model/message'

export interface IChat {
  messages: Message[]
  getSize(): number
  getMessages(): Message[]
  getFirstKey(): string
  getLastKey(): string
  addMessage(): void
  addMessages(newMessages: Message[]): void
}

class Chat {
  messages: Message[]

  constructor(messages: Message[]) {
    this.messages = messages
  }

  getSize() {
    return this.messages.length
  }

  getMessages() {
    return this.messages
  }

  getFirstKey() {
    return this.messages[0].getID()
  }

  getLastKey() {
    return this.messages[this.getSize() - 1].getID()
  }

  addMessage(newMessage) {
    const messageExist = this.messages.find((message) => message.timestamp === newMessage.timestamp)

    if (!messageExist) {
      this.messages.push(newMessage)
    }
  }

  removeMessage(newMessage) {
    // const messageExist = this.messages.find((message) => message.timestamp === newMessage.timestamp)

    const newMessages = this.messages.filter((message) => {
      return message.timestamp != newMessage.timestamp
    })
    this.messages = [...newMessages]
  }

  addOlderMessages(newMessages: Message[]) {
    this.messages = [...newMessages, ...this.messages.slice(1)].sort(
      (first, second) => first.timestamp - second.timestamp,
    )
  }
}

export default Chat
