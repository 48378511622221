import React, { createContext, useState, useContext } from 'react'
import User from 'src/model/user'
import ApiGateway from 'theme/api/api-gateway'
import { useFirebaseService } from 'theme/services/firebase-service'
import { useAuthService } from 'theme/services/auth/auth-service'
import { useAsyncRequestQueue } from 'src/hooks/useAsyncRequestQueue'
import { isEmpty } from 'theme/utils/generic-helpers'

export type UserMapType = Record<string, User>

interface IManagementService {
  getAllUsersMap: (withDetails: boolean) => UserMapType
}

const ManagementContext = createContext<IManagementService | null>(null)

export const ManagementServiceProvider = ({ children }: React.HTMLProps<HTMLDivElement>) => {
  const firebase = useFirebaseService()
  const authService = useAuthService()
  const asyncRequestQueue = useAsyncRequestQueue()
  // @ts-ignore
  const user = authService.user
  const [allUsers, setAllUsers] = useState<UserMapType>({})

  const getAllUsersMap = (withDetails = false) => {
    if (isEmpty(allUsers) && user) {
      asyncRequestQueue.addTask('fetchAllUsers', {
        requestParams: [firebase, withDetails],
        request: ApiGateway.getAllUsers,
        callback: setAllUsers,
      })
    }

    return allUsers
  }

  const contextValue = {
    getAllUsersMap,
  }

  return <ManagementContext.Provider value={contextValue}>{children}</ManagementContext.Provider>
}

export const useManagementService = () => {
  const context = useContext(ManagementContext)

  if (context === null) {
    throw 'Management Context is not initialized!'
  }

  return context
}
