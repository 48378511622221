import Message from 'src/chat/model/message'
import Observable from 'src/chat/model/observable'
import Chat from 'src/chat/model/chat'
import ConversationListItem from 'src/chat/model/conversation-list-item'
import {
  getDatabase,
  get,
  push,
  onValue,
  ref,
  set,
  limitToLast,
  endAt,
  query,
  startAt,
  limitToFirst,
  onChildAdded,
  runTransaction,
  remove,
} from 'firebase/database'

const DEFAULT_LIMIT = 1000
const PULL_LIMIT = 10
const LAST_MESSAGE = 'latest'
const JUST_NEW_MESSAGE = 'new-messages'
const JUST_DELETED_MESSAGE = 'deleted-messages'

interface IChatDatabase {
  getOldMessages(self: string, destination: string, key?: string): Promise<Chat>
  observeNewMessages(self: string, destination: string, key?: string): Observable<Message>
  observeRoomNewMessages(roomName: string): Observable<Message>
  observeRoomDeletedMessages(roomName: string): Observable<Message>
  sendMessage(sender: string, destination: string, message: Message, isChatEnabled: boolean): void
  deleteMessage(roomId: string, messageId: string): void
  pinMessage(roomId: string, messageId: string): void
  removePinnedMessage(roomId: string): void
  observePinnedMessage(roomId: string): Observable<string>
  observeTyping(self: string, destination: string): Observable<null | boolean>
  observeDestinationChat(self: string, destination: string): Observable<boolean>
  setChatEnabled(self: string, destination: string, value: boolean): Observable<null>
  setTyping(self: string, destination: string, value: boolean): void
  getTypingObserver(self: string, destination: string, value: boolean): Observable<null>
  observeConversationsFor(self: string): Observable<ConversationListItem[]>
  getRoomChat(roomName: string, key?: string): Promise<Chat>
  sendRoomMessage(roomName: string, message: Message): void
  nullifyUnreadCount(self: string, destination: string): void
}

class ChatDatabase implements IChatDatabase {
  firebaseDb: any

  constructor(firebaseApp) {
    this.firebaseDb = getDatabase(firebaseApp)
  }

  nullifyUnreadCount(self: string, destination: string): void {
    set(ref(this.firebaseDb, `chat/${self}/conversations/${destination}/unreadCount`), 0)
  }

  observeDestinationChat(self: string, destination: string): Observable<boolean> {
    const chatEnabledRef = ref(this.firebaseDb, `chat/${destination}/conversations/${self}/enabled`)
    const observer = new Observable<boolean>(chatEnabledRef)

    onValue(
      chatEnabledRef,
      (snapshot) => {
        const isEnabled = snapshot.exists() ? snapshot.val() : false

        observer.emit(isEnabled)
      },
      (error) => {
        console.error(error)
      },
    )

    return observer
  }

  setChatEnabled(self, destination, value) {
    const chatEnabledRef = ref(this.firebaseDb, `chat/${self}/conversations/${destination}/enabled`)
    set(chatEnabledRef, value)

    const observer = new Observable<null>(chatEnabledRef)

    observer.setOnDisconnect(false)

    return observer
  }

  async getRoomChat(roomName: string, key = LAST_MESSAGE): Promise<Chat> {
    const chatMessages =
      key === LAST_MESSAGE
        ? await get(
            query(ref(this.firebaseDb, `chatRooms/${roomName}/messages`), ...[limitToLast(PULL_LIMIT)]),
          )
        : await get(
            query(
              ref(this.firebaseDb, `chatRooms/${roomName}/messages`),
              ...[endAt(null, key), limitToLast(PULL_LIMIT)],
            ),
          )

    let messages: Message[] = []

    if (chatMessages.exists()) {
      const messagesSnaps = chatMessages.val()

      if (messagesSnaps) {
        const values: Message[] = Object.values(messagesSnaps)
        const keys = Object.keys(messagesSnaps)

        messages = values.map((messageObject, idx) => {
          return new Message({
            ...messageObject,
            id: keys[idx],
          })
        })
      }
    }

    return new Chat(messages)
  }

  observeNewMessages(self: string, destination: string, key = JUST_NEW_MESSAGE): Observable<Message> {
    const messagesRef =
      key === JUST_NEW_MESSAGE
        ? query(ref(this.firebaseDb, `chat/${self}/messages/${destination}`), ...[limitToLast(1)])
        : query(
            ref(this.firebaseDb, `chat/${self}/messages/${destination}`),
            ...[startAt(null, key), limitToFirst(DEFAULT_LIMIT)],
          )

    const observer = new Observable<Message>(messagesRef)

    onChildAdded(
      messagesRef,
      (snapshot) => {
        const id = snapshot.key
        const data = snapshot.val()
        const message = new Message({
          ...data,
          id,
        })

        observer.emit(message)
      },
      (error) => {
        console.error(error)
      },
    )

    return observer
  }

  sendMessage(sender: string, destination: string, message: Message, isChatEnabled: boolean) {
    push(ref(this.firebaseDb, `/chat/${sender}/messages/${destination}`), message)
    push(ref(this.firebaseDb, `/chat/${destination}/messages/${sender}`), message)

    const conversationItem = {
      timestamp: message.getTimestamp(),
      sender: message.getSender(),
      message: message.getMessage(),
    }

    //TODO: save unread messages depends on enabled chat, add observing for unread and nullify unread once chat enabled
    runTransaction(ref(this.firebaseDb, `/chat/${sender}/conversations/${destination}`), () => ({
      ...conversationItem,
      unreadCount: 0,
      enabled: true,
    }))

    runTransaction(ref(this.firebaseDb, `/chat/${destination}/conversations/${sender}`), (item) => {
      if (item) {
        const currentVal = item.unreadCount ?? 0
        const unreadCount = isChatEnabled ? 0 : currentVal + 1
        return {
          ...conversationItem,
          unreadCount,
          enabled: isChatEnabled,
        }
      }

      return {
        ...conversationItem,
        unreadCount: 1,
        enabled: isChatEnabled,
      }
    })
  }

  deleteMessage(roomId: string, messageId: string) {
    const oldRef = ref(this.firebaseDb, `/chatRooms/${roomId}/messages/${messageId}`)
    const newRef = ref(this.firebaseDb, `/chatRooms/${roomId}/deletedMessages/${messageId}`)

    onValue(oldRef, function (snap) {
      set(newRef, snap.val())
        .then(() => {
          remove(oldRef)
        })
        .catch((error) => {
          if (typeof console !== 'undefined' && console.error) {
            console.error(error)
          }
        })
    })
  }

  pinMessage(roomId: string, messageId: string) {
    const pinnedMessageChatRef = ref(this.firebaseDb, `/chatRooms/${roomId}/pinned/`)

    set(pinnedMessageChatRef, {
      messageId,
    })
  }

  removePinnedMessage(roomId: string) {
    const pinnedMessageChatRef = ref(this.firebaseDb, `/chatRooms/${roomId}/pinned/`)

    remove(pinnedMessageChatRef)
  }

  observePinnedMessage(roomId: string) {
    const pinnedMessageChatRef = ref(this.firebaseDb, `/chatRooms/${roomId}/pinned/`)

    const observer = new Observable<string>(pinnedMessageChatRef)

    onValue(
      pinnedMessageChatRef,
      (snapshot) => {
        const pinnedMessageId = snapshot.exists() ? snapshot.val()?.messageId : null

        observer.emit(pinnedMessageId)
      },
      (error) => {
        console.error(error)
      },
    )

    return observer
  }

  async getOldMessages(self: string, destination: string, key = LAST_MESSAGE): Promise<Chat> {
    const userOldMessages =
      key === LAST_MESSAGE
        ? await get(
            query(
              ref(this.firebaseDb, `/chat/${self}/messages/${destination}`),
              ...[limitToLast(PULL_LIMIT)],
            ),
          )
        : await get(
            query(
              ref(this.firebaseDb, `/chat/${self}/messages/${destination}`),
              ...[endAt(null, key), limitToLast(PULL_LIMIT)],
            ),
          )

    let messages: Message[] = []

    if (userOldMessages.exists()) {
      const chatObject = userOldMessages.val()

      if (chatObject) {
        const values: Message[] = Object.values(chatObject)
        const keys = Object.keys(chatObject)

        messages = values.map((messageObject, idx) => {
          return new Message({
            ...messageObject,
            id: keys[idx],
          })
        })
      }
    }

    return new Chat(messages)
  }

  observeTyping(self: string, destination: string): Observable<null | boolean> {
    const messagesRef = ref(this.firebaseDb, `chat/${self}/conversations/${destination}/typing`)

    const observer = new Observable<boolean | null>(messagesRef)

    onValue(
      messagesRef,
      (snapshot) => {
        const isTyping = snapshot.exists() ? snapshot.val() : null

        observer.emit(isTyping)
      },
      (error) => {
        console.error(error)
      },
    )

    return observer
  }

  getTypingObserver(self: string, destination: string) {
    const typingRef = ref(this.firebaseDb, `/chat/${self}/conversations/${destination}/typing`)
    const observer = new Observable<null>(typingRef)

    observer.setOnDisconnect(false)

    return observer
  }

  setTyping(self: string, destination: string, value: boolean) {
    set(ref(this.firebaseDb, `/chat/${self}/conversations/${destination}/typing`), value)
  }

  observeConversationsFor(self: string): Observable<ConversationListItem[]> {
    const userChatRef = ref(this.firebaseDb, `chat/${self}/conversations`)

    const observer = new Observable<ConversationListItem[]>(userChatRef)

    onValue(
      userChatRef,
      (snapshot) => {
        const chatVal = snapshot.exists() ? snapshot.val() : false

        const allChats: Array<any> = chatVal ? Object.values(chatVal) : []
        const chatsUid: string[] = chatVal ? Object.keys(chatVal) : []

        const chats = allChats
          .map((chat, idx) => ({
            ...chat,
            uid: chatsUid[idx],
          }))
          .filter(({ message }) => !!message)

        const conversationListItems = chats.map((chat) => {
          const unreadCount = chat.unreadCount || 0

          return new ConversationListItem(
            new Message({
              message: chat.message,
              timestamp: chat.timestamp,
              sender: chat.setTimeout,
            }),
            unreadCount,
            chat.uid,
          )
        })

        observer.emit(conversationListItems)
      },
      (error) => {
        console.error(error)
      },
    )

    return observer
  }

  sendRoomMessage(roomName: string, message: Message): void {
    push(ref(this.firebaseDb, `/chatRooms/${roomName}/messages`), message)
  }

  observeRoomNewMessages(roomName: string): Observable<Message> {
    const messagesRef = query(ref(this.firebaseDb, `/chatRooms/${roomName}/messages`), ...[limitToLast(1)])
    const observer = new Observable<Message>(messagesRef)

    onChildAdded(
      messagesRef,
      (snapshot) => {
        const id = snapshot.key
        const data = snapshot.val()
        const message = new Message({
          ...data,
          id,
        })

        observer.emit(message)
      },
      (error) => {
        console.error(error)
      },
    )

    return observer
  }

  observeRoomDeletedMessages(roomName: string): Observable<Message> {
    const messagesRef = query(
      ref(this.firebaseDb, `/chatRooms/${roomName}/deletedMessages`),
      ...[limitToLast(1)],
    )
    const observer = new Observable<Message>(messagesRef)

    onChildAdded(
      messagesRef,
      (snapshot) => {
        const id = snapshot.key
        const data = snapshot.val()
        const message = new Message({
          ...data,
          id,
        })

        observer.emit(message)
      },
      (error) => {
        console.error(error)
      },
    )

    return observer
  }
}

export default ChatDatabase
